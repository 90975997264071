import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../components/layout';
import Seo from "../../components/seo"
import MarketResearchIcon from '../../icons/market-research.svg';
import CommunicateIcon from '../../icons/communicate.svg';
import DesignIcon from '../../icons/design.svg';
import InformationDesignIcon from '../../icons/statistics-results.svg';
import PlatformTestingIcon from '../../icons/everything.svg';
import ScrollContainer from 'react-indiana-drag-scroll';
import Quote from '../../components/quote';
import ContactForm from '../../components/contact-form';
import PortfolioFeed from '../../components/portfolio-feed';

const PortfolioHeader = () => (
  <section className="bg-gray-700 py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Eridan</p>
            </div>
            <div className="col-12 mt-0 mt-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">Logo Design</p>
              <p className="text-white">Graphic Design</p>
              <p className="text-white">UX/UI Design</p>
              <p className="text-white">Website Design</p>
              <p className="text-white">WordPress Website Build</p>
              <p className="text-white">Monitoring and Maintenance</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 SOLUTIONS DESIGNED A LOGO, SUPPORTING VISUAL ASSETS AND
            DEVELOPED A WEBSITE TO SHOWCASE AND ORDER THE CLIENTS’ NEW PRODUCT.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const ShowcaseImage = () => (
  <section className="pt-4">
    <div className="container-fluid">
      <div className="row d-flex justify-content-center">
        <div className="col-10">
          <StaticImage
            src="../../images/portfolio/eridan/eridan-showcase.png"
            alt="Eridan"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>
            Eridan developed a new transceiver and was looking for a one-stop
            design and development partner that can help get their website up in
            record time to support their product launch.
          </p>
          <p>
            The Eridan team wanted to be able to access the website after launch
            to perform content updates on their own.
          </p>
          <p>
            Eridan’s logo needed a refresh and wanted to unveil the new logo
            with the launch of the new website.
          </p>
          <p>
            Additionally, their team provided sketches that needed to be
            translated into high quality, product relevant infographics and
            imagery.
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Project Goals</h2>
          <ol className="text-primary">
            <li>
              <span className="text-dark">
                Determine the full scope of the project
              </span>
            </li>
            <li>
              <span className="text-dark">Redesign Eridan's logo</span>
            </li>
            <li>
              <span className="text-dark">
                Design product related infographic and imagery
              </span>
            </li>
            <li>
              <span className="text-dark">
                Build a website to showcase Eridan's product
              </span>
            </li>
            <li>
              <span className="text-dark">
                Incorporate a way to order the product
              </span>
            </li>
            <li>
              <span className="text-dark">Utilize a user friendly CMS</span>
            </li>
            <li>
              <span className="text-dark">
                Launch the website and brand identity in record time
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);

const HowWeDidIt = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-xd.png"
                        alt="Adobe Xd"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-illustrator.png"
                        alt="Adobe Illustrator"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-photoshop.png"
                        alt="Adobe Photoshop"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/wordpress.png"
                        alt="Wordpress"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h2 className="mt-5 mb-2">Development Languages</h2>
                  <p className="mb-0">PHP</p>
                  <p className="mb-0">JavaScript</p>
                  <p className="mb-0">HTML 5</p>
                  <p className="mb-0">CSS 3</p>
                </div>
                <div className="col-12">
                  <h2 className="mt-5 mb-2">Database</h2>
                  <p className="mb-0">MySQL</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2 mt-5">Research Methods</h2>
              <div className="row align-items-center">
                <div className="col-2">
                  <MarketResearchIcon color="gray" />
                </div>
                <div className="col-10">
                  <p>Market Research</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-2">
                  <CommunicateIcon color="gray" />
                </div>
                <div className="col-10">Client Interviews</div>
                <div className="col-2">
                  <DesignIcon color="gray" />
                </div>
                <div className="col-10">
                  <p>Design Workshops</p>
                </div>
                <div className="col-2">
                  <InformationDesignIcon color="gray" />
                </div>
                <div className="col-10">
                  <p>Informational Design Research</p>
                </div>
                <div className="col-2">
                  <PlatformTestingIcon color="gray" />
                </div>
                <div className="col-10">
                  <p>Platform Testing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const LogoColorPallete = () => (
  <section>
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-12 col-lg-6">
          <StaticImage
            src="../../images/portfolio/eridan/eridan-logo-texture.jpg"
            alt="Eridan logo on texture background"
          />
        </div>
        <div className="col-12 col-lg-6">
          <StaticImage
            src="../../images/portfolio/eridan/eridan-color-pallette.jpg"
            alt="Eridan color pallette"
          />
        </div>
      </div>
    </div>
  </section>
);

const LogoProcess = () => (
  <section>
    <div className="container py-5">
      <div className="row">
        <div className="col-12">
          <h2>From Client Sketch To Final Logo</h2>
        </div>
      </div>
    </div>
    <ScrollContainer vertical={false} className="scroll-carousel py-6">
      <StaticImage
        height="360"
        layout="fixed"
        src="../../images/portfolio/eridan/eridan-logo-process-slide.jpg"
        alt="Portfolio Slide 1"
        className="scroll-carousel-item mr-5"
        imgClassName="scroll-carousel-img"
      />
    </ScrollContainer>
  </section>
);

const KeyFocusAreas = () => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Key Focus Areas</h2>
          <p>
            How can we capture Eridan’s vision and identity roots in their new
            logo?
          </p>
          <p>
            How do we best portray and visualize the complex, technical product
            details?
          </p>
          <p>
            What platform and development approach do we use to build the
            website quickly and within budget?
          </p>
          <p>
            What platform will be easy for the client to update and maintain in
            the future?
          </p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>Core Client Needs</h2>
          <div className="row pr-md-6">
            <div className="col-12 mb-4">
              <p>
                <strong>Cohesive Branding</strong>
                <br></br>The brand guide must define our brand and provide a
                library of assets to ensure it is used cohesively.
              </p>
            </div>
            <div className="col-12 mb-4">
              <p>
                <strong>Central Location</strong>
                <br></br>All tools and assets must be stored and updated in one
                central location.
              </p>
            </div>
            <div className="col-12 mb-4">
              <p>
                <strong>Easy to Use</strong>
                <br></br>Our teams and external vendors should be able to use
                the brand guide and assets with little to no instruction.
              </p>
            </div>
            <div className="col-12 mb-4">
              <p>
                <strong>Resource for All</strong>
                <br></br>The brand guide must benefit and help save time for all
                our internal teams as well as external partners.
              </p>
            </div>
            <div className="col-12">
              <p>
                <strong>Easy to Maintain and Grow</strong>
                <br></br>The platform must be easy to update, maintain, and must
                have the capability to grow.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Mockups = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Wordpress Showcase</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3">
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-home.jpg"
            alt="Eridan desktop mockup for home page"
            className="shadow"
          />
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-order-a-devkit.png"
            alt="Eridan desktop mockup for order a devkit page"
            className="shadow mt-5"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-applications.jpg"
            alt="Eridan desktop mockup for applications page"
            className="shadow mt-5 mt-md-0"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-product.png"
            alt="Eridan desktop mockup for product page"
            className="shadow mt-5 mt-lg-0"
          />
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-devkit.png"
            alt="Eridan desktop mockup for devkit page"
            className="shadow mt-5"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-careers.jpg"
            alt="Eridan desktop mockup for careers page"
            className="shadow mt-5 mt-md-0"
          />
          <StaticImage
            src="../../images/portfolio/eridan/desktop-mockup-about.jpg"
            alt="Eridan desktop mockup for about page"
            className="shadow mt-5"
          />
        </div>
      </div>
    </div>
  </section>
);

const TechnicalMockups = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Technical Drawings</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <StaticImage
            src="../../images/portfolio/eridan/eridan-techdraw-waves.jpg"
            alt="Eridan technical drawings for transceiver waves"
          />
        </div>
        <div className="col-12 mt-4">
          <StaticImage
            src="../../images/portfolio/eridan/eridan-techdraw-transceiver.jpg"
            alt="Eridan technical drawings for transceiver"
          />
        </div>
      </div>
    </div>
  </section>
);

const EridanQuote = () => (
  <section className="mt-6 mb-0 bg-dark">
    <div className="container">
      <div className="row align-items-center p-5">
        <div className="col-12 col-lg-6">
          <Quote
            dark
            quote="729 Solutions took care of the entire project for us, and gave us exactly what we needed, within our budget."
            name="Amy Lamboley"
            company="Eridan Communications"
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="p-6">
            <StaticImage
              style={{ width: '100%' }}
              src="../../images/portfolio/eridan/eridan_logo_wht.png"
              alt="Eridan logo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Learnings = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Learnings</h2>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-6 pr-md-6">
              <p>
                This was an interesting challenge that required close teamwork
                between our design and development team. Due to the short
                deadline we had to build the website while working on a new
                brand identity.
              </p>
              <p>
                The design team worked closely with the client to finalize the
                brand visuals in a phased approach so the development wouldn’t
                come to a halt. Simultaneously, the design team created visual
                assets for each page of the website.
              </p>
              <p>
                Our dev team created the framework and built the website in a
                way that made the brand implementation quick and easy. Our teams
                came together and had a unified vision throughout the design and
                dev process.
              </p>
            </div>
            <div className="col-12 col-lg-6 pr-md-6">
              <p>
                729 has highly-skilled design and development teams offering a
                wide variety of software development and graphic design skills,
                giving clients such as Eridan the option to use a single
                provider for project execution.
              </p>
              <p>
                Overall, this project allowed 729 Solutions to showcase our
                skills in deploying the best methodologies to solve complex
                challenges. This project specifically proved that we can offer a
                one-stop-shop, from research to design and design to
                development, all in fast turnaround times when the need arises,
                which truly delights our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6 mt-0 mb-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 mb-5 mb-lg-0">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{' '}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{' '}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit"  location={location}/>
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Eridan" 
      />
      <PortfolioHeader />
      <ShowcaseImage />
      <ProjectGoals />
      <HowWeDidIt />
      <LogoColorPallete />
      <LogoProcess />
      <KeyFocusAreas />
      <Mockups />
      <TechnicalMockups />
      <EridanQuote />
      <Learnings />
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6" />
    </Layout>
  );
};

export default Index;
